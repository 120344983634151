<template>
  <div>
    <b-row class="no-gutters">
      <b-col>
        <h1 class="font-weight-bold header-main text-uppercase mb-3">
          ข้อมูลหมวดหมู่บทความ
        </h1>
      </b-col>
    </b-row>
    <div class="bg-white p-3" v-if="$isLoading">
      <b-row class="pl-1">
        <b-col cols="6">
          <div class="panel d-flex align-items-md-center">
            <!-- <b-form-checkbox size="lg" id="highlight"
                  >เป็นไฮไลท์</b-form-checkbox
                > -->
            <b-form-checkbox
              size="lg"
              id="sameLang"
              class="mt-0 mt-sm-3"
              @change="useSameLanguage"
              v-model="form.isSameLanguage"
              >ใช้เหมือนกันทุกภาษา
            </b-form-checkbox>
          </div>
        </b-col>
        <b-col cols="6" class="text-right">
          <div class="panel">
            <b-button
              type="button"
              class="btn btn-language"
              v-for="(language, index) in languageList"
              v-bind:key="index"
              v-bind:class="[languageActive == language.id ? 'active' : '']"
              @click="changeLanguage(language.id, index)"
              :disabled="form.isSameLanguage ? true : false"
            >
              <span class="text-uppercase">{{ language.nation }}</span>
            </b-button>
          </div>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col md="6">
          <div
            v-for="(item, index) in form.newsCategoryTranslations"
            v-bind:key="index"
          >
            <div
              v-bind:class="[languageActive == item.languageId ? '' : 'd-none']"
            >
              <InputText
                textFloat="ชื่อหมวดหมู่"
                placeholder="ชื่อหมวดหมู่"
                type="text"
                name="name"
                v-model="item.name"
                isRequired
                :isValidate="
                  $v.form.newsCategoryTranslations.$each.$iter[index].name
                    .$error
                "
                :v="$v.form.newsCategoryTranslations.$each.$iter[index].name"
                @onKeyup="setUrlKeyByLang(item.name, index)"
              />
            </div>
          </div>
        </b-col>
        <b-col md="6">
          <div
            v-for="(item, index) in form.newsCategoryTranslations"
            v-bind:key="index"
          >
            <div
              v-bind:class="[languageActive == item.languageId ? '' : 'd-none']"
            >
              <InputText
                textFloat="URL Key"
                placeholder="URL Key"
                type="text"
                name="name"
                v-model="form.urlKey"
                isRequired
                :isValidate="$v.form.urlKey.$error"
                :v="$v.form.urlKey"
                @onKeyup="setUrlKeyByLang(form.urlKey, index)"
              />
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6">
          <InputText
            textFloat="ลำดับการแสดง"
            placeholder="ลำดับการแสดง"
            type="text"
            name="sortorder"
            @onKeypress="isNumber($event)"
            v-model="form.sortOrder"
          />
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col>
          <label class="font-weight-bold main-label">
            สถานะการแสดงผล
            <span class="text-danger">*</span>
          </label>
          <div>
            <b-form-checkbox
              switch
              v-model="display"
              class="radio-active"
              size="lg"
            >
              <span class="ml-2 main-label">{{
                display ? "ใช้งาน" : "ไม่ใช้งาน"
              }}</span>
            </b-form-checkbox>
          </div>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col md="6">
          <b-button
            v-if="isEdit"
            class="btn btn-danger btn-details-set mr-md-2"
            :disabled="isDisable"
            @click="openModalDelete(form.banner.name)"
            >ลบ</b-button
          >
          <router-link to="/category/news">
            <b-button :disabled="isDisable" class="btn-details-set btn-cancel"
              >ย้อนกลับ</b-button
            >
          </router-link>
        </b-col>
        <b-col md="6" class="text-sm-right">
          <button
            type="button"
            @click="checkForm(0)"
            :disabled="isDisable"
            class="btn btn-main btn-details-set ml-md-2 text-uppercase"
          >
            บันทึก
          </button>
          <button
            type="button"
            @click="checkForm(1)"
            :disabled="isDisable"
            class="btn btn-main btn-details-set ml-md-2 text-uppercase"
          >
            บันทึกและออก
          </button>
        </b-col>
      </b-row>
    </div>
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalLoading ref="modalLoading" :hasClose="false" />
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import { required } from "vuelidate/lib/validators";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import Vue from "vue";

export default {
  name: "CategoryNewsDetails",
  components: {
    InputText,
    ModalAlert,
    ModalAlertError,
    ModalLoading,
  },
  data() {
    return {
      id: this.$route.params.id,
      isLoadingMobileImage: false,
      isEdit: false,
      modalMessage: "",
      isDisable: false,
      languageList: [],
      languageActive: 1,
      display: false,
      form: {
        id: 0,
        display: 0,
        sortOrder: 0,
        isSameLanguage: true,
        newsCategoryTranslations: [
          {
            name: "",
            languageId: 1,
            newsCategoryId: 0,
            updatedBy: "",
            createdTime: "",
            updatedTime: "",
          },
          {
            name: "",
            languageId: 2,
            newsCategoryId: 0,
            createdBy: "",
            updatedBy: "",
            createdTime: "",
            updatedTime: "",
          },
        ],
        createdBy: "",
        updatedBy: "",
        createdTime: "",
        updatedTime: "",
      },
    };
  },
  validations: {
    form: {
      newsCategoryTranslations: {
        $each: {
          name: { required },
        },
      },
      urlKey: { required },
    },
  },
  created: async function () {
    await this.getData();
    await this.changeLanguage(1, 0);
  },
  methods: {
    getData: async function () {
      this.$isLoading = false;

      let languages = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/language`,
        null,
        this.$headers,
        null
      );
      if (languages.result == 1) {
        this.languageList = languages.detail;
      }

      let data = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/News/GetCategory/${this.id}`,
        null,
        this.$headers,
        null
      );

      if (data.result == 1) {
        this.form = data.detail;
        this.display = this.form.display === 1 ? true : false;
        if (this.form.id == 0) {
          this.form.isSameLanguage = true;
        }
      }
      this.$isLoading = true;
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    changeSameLang: function (value) {
      this.form.isSameLanguage = value;
    },
    changeLanguage(id, index) {
      if (!this.form.isSameLanguage) {
        this.languageActive = id;
      }
    },
    useSameLanguage: async function () {
      Vue.nextTick(() => {
        if (this.form.isSameLanguage) {
          this.form.mainLanguageId = this.languageActive;
          let data = this.form.newsCategoryTranslations.filter(
            (val) => val.languageId == this.form.mainLanguageId
          );

          if (data.length == 1) {
            data = data[0];
            for (
              let index = 0;
              index < this.form.newsCategoryTranslations.length;
              index++
            ) {
              this.form.newsCategoryTranslations[index].name = data.name;
            }
          }
        } else {
          var index = this.languageList
            .map(function (x) {
              return x.id;
            })
            .indexOf(this.languageActive);

          let data = this.form.newsCategoryTranslations.filter(
            (val) => val.languageId != this.form.mainLanguageId
          );
          if (this.id == 0) {
            if (data.length == 1) {
              data = data[0];
              data.altTag = "";
            }
          }
        }
      });
    },
    setUrlKeyByLang: function (value, index) {
      this.form.urlKey = value.replace(/ /g, "-");
    },
    checkForm: async function (flag) {
      this.form.display = this.display ? 1 : 0;
      if (this.form.isSameLanguage) {
        await this.useSameLanguage();
      }

      this.$v.$touch();
      if (this.$v.$error) {
        await this.checkValidateTranslationList();
        return;
      }
      this.modalAlertShow = false;
      this.flag = flag;
      this.submit();
    },
    checkValidateTranslationList: async function () {
      let isError = false;
      this.languageList.forEach((element, index) => {
        if (!isError) {
          if (this.$v.form.newsCategoryTranslations.$each.$iter[index].$error) {
            this.languageActive =
              this.$v.form.newsCategoryTranslations.$model[index].languageId;

            isError = true;
          }
        }
      });
    },
    submit: async function () {
      this.isDisable = true;
      this.$refs.modalLoading.show();

      const baslUrl =
        this.id == 0
          ? `${this.$baseUrl}/api/News/SaveCategory`
          : `${this.$baseUrl}/api/News/UpdateCategory`;

      let data = await this.$callApi(
        "post",
        baslUrl,
        null,
        this.$headers,
        this.form
      );

      this.modalMessage = data.message;
      this.isDisable = false;
      this.$refs.modalLoading.hide();

      if (data.result == 1) {
        this.modalMessage = "สำเร็จ";
        this.$refs.modalAlert.show();

        if (this.flag == 1) {
          setTimeout(() => {
            this.$router.push({
              path: `/category/news`,
            });
          }, 3000);
        } else {
          setTimeout(() => {
            this.$refs.modalAlert.hide();
          }, 3000);

          if (this.id > 0) {
            this.getData();
          }
        }
      } else {
        this.modalMessage = data.detail;
        this.$refs.modalAlertError.show();
      }
    },
  },
};
</script>

<style scoped>
.panel {
  font-size: 17px;
  color: #16274a;
}
</style>
